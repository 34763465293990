<template>
  <div class="ticketList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>门票列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称:</span>
      <el-input v-model="list.ticketName" placeholder="请输入门票名称"></el-input>
      <span>标签:</span>
      <el-input v-model="list.label" placeholder="请输入标签名称"></el-input>
      <span>所属景区:</span>
      <el-select
        clearable
        @clear="onClear(5)"
        v-model="list.sceneryId"
        placeholder="请选择"
      >
        <el-option
          v-for="item in sceneryOptions"
          :key="item.cateId"
          :label="item.cateName"
          :value="item.cateId"
        >
        </el-option>
      </el-select>
      <span>状态:</span>
      <el-select clearable v-model="list.status" placeholder="请选择" @clear="onClear(2)">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div style="margin-top: 15px">
        <span>是否显示:</span>
        <el-select clearable v-model="list.isShow" placeholder="请选择">
          <el-option
            v-for="item in isShowOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>万旅网显示:</span>
        <el-select clearable v-model="list.wlShow" placeholder="请选择">
          <el-option
            v-for="item in optionsShow"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getTicketList()">查找</el-button>
        <el-button
          type="primary"
          @click="$router.push('/addTicket/0')"
          v-if="$store.state.powerList.indexOf('scenery:ticket:list:insert') !== -1"
          >添加</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :row-key="row => row.ticketId"
      @select="onSelectChange"
      @select-all="onSelectChange"
    >
      <el-table-column type="selection" :reserve-selection="true" prop="prop" width="width"> </el-table-column>
      <el-table-column header-align="center" align="center" prop="dept" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="ticketId"
        label="门票ID"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="sceneryName"
        label="所属景区"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="ticketName"
        label="门票名称"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="ticketLabel"
        label="标签"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="strSellWay"
        label="销售方式"
      >
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="unsubscribe" label="是否退订">
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="strTj" label="是否推荐">
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="showImg" label="展示图">
        <template slot-scope="{ row }">
          <el-image
            style="width: 80px; height: 80px"
            :src="row.showImg"
            :preview-src-list="[row.showImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="strStatus" label="状态">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="cateName"
        label="分类名称"
      >
      </el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示"> </el-table-column>
      <el-table-column align="center" prop="wlShow" label="万旅网显示"> </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="prop"
        label="操作"
        width="350"
      >
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-show="row.status == 2"
            @click="onChangeRowStatus(row, 1)"
            v-if="$store.state.powerList.indexOf('scenery:ticket:list:up') !== -1"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-show="row.status == 1"
            v-if="$store.state.powerList.indexOf('scenery:ticket:list:down') !== -1"
            @click="onChangeRowStatus(row, 2)"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/addTicket/${row.ticketId}`)"
            v-if="$store.state.powerList.indexOf('scenery:ticket:list:update') !== -1"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/ticketPrice/${row.ticketId}`)"
            v-if="$store.state.powerList.indexOf('scenery:ticket:list:price') !== -1"
            >设置价格</el-button
          >
          <el-dropdown style="margin-left: 10px">
            <el-button type="primary" size="mini">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  @click="$router.push(`/ticketLog/${row.ticketId}`)"
                  v-if="$store.state.powerList.indexOf('scenery:ticket:list:log') !== -1"
                  >查看日志</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  style="width: 75px"
                  @click="onShowAddress(row.ticketId)"
                  >查看页面路径</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  style="width: 75px"
                  @click="onChangeRowStatus(row, 0)"
                  v-if="
                    $store.state.powerList.indexOf('scenery:ticket:list:delete') !== -1
                  "
                  >删除</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  style="width: 75px"
                  @click="onShowAddress(row.ticketId)"
                  >查看页面路径</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="addByCodyTicket(row.ticketId)"
                  v-if="$store.state.powerList.indexOf('scenery:ticket:list:add') !== -1"
                  >一键复制</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="onGOGroupBuy(row.ticketId)"
                  >去拼团</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="onGOSecKill(row.ticketId)"
                  >去秒杀</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="creatCode(row.ticketId)"
                  >生成二维码</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeAllStatus(1)"
        v-if="$store.state.powerList.indexOf('scenery:ticket:list:up') !== -1"
        >批量上架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatus(2)"
        v-if="$store.state.powerList.indexOf('scenery:ticket:list:down') !== -1"
        >批量下架</el-button
      >
      <el-button type="primary" @click="onTj(1)">批量推荐</el-button>
      <el-button type="primary" @click="onTj(0)">批量不推荐</el-button>
      <el-button
        type="primary"
        @click="onChangeAllStatus(0)"
        v-if="$store.state.powerList.indexOf('scenery:ticket:list:delete') !== -1"
        >批量删除</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(1)"
        v-if="$store.state.powerList.indexOf('scenery:ticket:list:wlShow') !== -1"
        >万旅网批量显示</el-button
      >

      <el-button
        type="primary"
        @click="onChangeAllStatusShow(0)"
        v-if="$store.state.powerList.indexOf('scenery:ticket:list:wlShow') !== -1"
        >万旅网批量隐藏</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看门票页面地址" :visible.sync="showAddress" width="35%">
      <div id="address">
        门票页面地址:<el-input v-model="ticketAddress" readonly id="wrapper"></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="copyinviteUrl"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ticketList, changeSort, changeStatus, addByCody,wanlvAllChange } from "../../api/ticket";
import { createEr3 } from "../../api/erCode";
import { isActivity } from "../../api/groupBuy";
import { isActivityKill } from "../../api/seckillInfo";
import { allScenery, tj } from "../../api/sceneryList";
export default {
  name: "ticketList",
  data() {
    return {
      ticketAddress: "",
      showAddress: false,

      QRcodeSize: "",
      codeVisible: false,
      ticketId: "",
      imgUrl: "",

      sceneryValue: "",
      sceneryOptions: [],
      sceneryOptions1: [],
      isShowOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      options: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      optionsShow: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      ids: "",

      tableData: [],
      pagination: {},

      list: {
        currentPage: 1,
        pageSize: 5,
        ticketName: "",
        sceneryId: null,
        label: "",
        status: null,
        isShow: -2,
        wlShow: -1,
      },
    };
  },
  watch: {
    "list.sceneryId": function () {
      this.$store.commit("setticketCateId", this.list.sceneryId);
    },
  },
  created() {
    this.list.sceneryId = this.$store.state.ticketCateId;

    this.getSceneryCateList();
    this.getTicketList();
  },
  methods: {
    onChangeAllStatusShow(status){
      if(!this.ids){
        return this.$message({
          type: "warning",
          message: "请选择要修改的内容!",
        });
      }
      // 是否修改
      this.$confirm("是否要批量修改选中门票的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(this.ids)
          const { data } = await wanlvAllChange({
            ticketId: this.ids,
            wlShow:status,
          });
          if (data.code === 0) {
            this.getTicketList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          
        });

    },
    onShowAddress(id) {
      this.ticketAddress =
        "wanXiaoDian/pages/ticket/ticketDetail/ticketDetail?scene=" + id + "_0";
      this.showAddress = true;
    },

    copyinviteUrl() {
      this.$nextTick(() => {
        var range = document.createRange();
        range.selectNode(document.getElementById("wrapper"));
        var selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },

    creatCode(id) {
      this.ticketId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr3({
          ticketId: this.ticketId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },

    async onGOSecKill(ticketId) {
      const { data } = await isActivityKill({
        prodType: 2,
        prodId: ticketId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addSeckill/0/${ticketId}/${2}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async onGOGroupBuy(ticketId) {
      const { data } = await isActivity({
        prodType: 2,
        prodId: ticketId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addGroupBuy/0/${ticketId}/${2}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async addByCodyTicket(ticketId) {
      this.$confirm("确定一键复制这条收据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await addByCody({
            ticketId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getTicketList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async getTicketList() {
      const { data } = await ticketList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async getSceneryCateList() {
      const { data } = await allScenery();
      this.sceneryOptions = data.data;
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.ticketId);
      });
      this.ids = arr.join(",");
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        ticketId: row.ticketId,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: "设置失败",
          type: "error",
        });
      } else {
        this.$message({
          message: "设置成功",
          type: "success",
        });
      }
      row.inputVisible = false;
    },
    onChangeRowStatus(row, status) {
      this.$confirm("是否要改变选中门票的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: row.ticketId + "",
            status,
          });
          if (data.code !== 0) {
            this.$message({
              type: "error",
              message: "设置失败!",
            });
          } else {
            this.getTicketList();
            this.$message({
              type: "success",
              message: "设置成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onChangeAllStatus(status) {
      this.$confirm("是否要批量改变选中门票的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids) {
            const { data } = await changeStatus({
              ids: this.ids,
              status,
            });
            if (data.code === 0) {
              this.getTicketList();
              this.$message({
                type: "success",
                message: data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          } else {
            this.$message({
              type: "error",
              message: "请选择要改变的内容!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onTj(status) {
      this.$confirm("是否要批量改变选中门票的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids) {
            const { data } = await tj({
              ids: this.ids,
              status,
            });
            if (data.code === 0) {
              this.getTicketList();
              this.$message({
                type: "success",
                message: data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          } else {
            this.$message({
              type: "error",
              message: "请选择要改变的内容!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onClear(i) {
      if (i === 1) {
        this.list.sceneryId = null;
      } else if (i === 2) {
        this.list.status = null;
      } else if (i === 5) {
        this.list.sceneryId = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getTicketList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getTicketList();
    },
  },
};
</script>
<style lang="less" scoped>
.ticketList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-select {
      margin: 0 20px;
    }
    .select {
      margin: 0 10px;
    }
    .el-input {
      width: 200px;
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .btn {
    float: left;
    margin-top: 50px;
  }
  .el-pagination {
    float: right;
    margin-top: 50px;
  }
}
</style>
